<template>
    <b-modal 
        title="Добавить майнер" 
        v-model="show" 
        centered 
        hide-footer
        @hide="$emit('closed')"
    >
        <validation-observer ref="form">
            <b-form>
                <b-form-group>
                    <validation-provider #default="{ errors }" name="miner" rules="required" >
                        <v-select
                          :options="miners"
                          v-model="miner"
                          label="name"
                          @search="fetchMiners"
                          :clearable="false"
                          placeholder="Выбрать майнер"
                        >
                            <template slot="no-options">
                                Нет результатов поиcка
                            </template>

                        </v-select>
                    </validation-provider>
                </b-form-group>
                <b-form-group>
                    <validation-provider #default="{ errors }" name="status" rules="required" >
                        <v-select
                          :options="statuses"
                          v-model="status"
                          label="name"
                          :clearable="false"
                          placeholder="Статус майнера"
                        >
                        </v-select>
                    </validation-provider>
                </b-form-group>
                <b-form-group v-if="status && status.value === 2">
                    <validation-provider #default="{ errors }" name="serial_number" rules="required" >
                        <b-form-input v-model="serial_number" placeholder="Серийный номер" />
                    </validation-provider>
                    <span class="text-muted font-small-3 pt-1">Для майнера со статусом "В работе" необходимо указать его серийный номер.</span>
                </b-form-group>

                <div class="d-flex justify-content-end mt-2">
                    <b-button @click="add" variant="primary">Добавить</b-button>
                </div>

            </b-form>
        </validation-observer>
        
    </b-modal>
</template>

<script>

    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    
    export default {

        data() {
            return {
                required,
                miners: [],
                statuses: [
                    { name: 'Доставка', value: 1 },
                    { name: 'В работе', value: 2 },
                ],
                miner: null,
                status: null,
                serial_number: null
            }
        },
        props: {
            contract: Number,
            show: Boolean
        },
        methods: {
            
            add( args ) {
                
                if( !this.miner ) {
                    return this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: `Ошибка добавления`,
                          text: `Укажите майнер для добавления к контракту`,
                          icon: 'AlertCircleIcon',
                          variant: 'danger',
                        }
                    });
                }
                
                if( !this.status ) {
                    return this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: `Ошибка добавления`,
                          text: `Укажите статус майнера`,
                          icon: 'AlertCircleIcon',
                          variant: 'danger',
                        }
                    });
                }
                
                if( this.status.value === 2 && !this.serial_number ) {
                    return this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: `Ошибка добавления`,
                          text: `Для майнера в статусе "В работе" нужно указать его серийный номер.`,
                          icon: 'AlertCircleIcon',
                          variant: 'danger',
                        }
                    });
                }
                
                
                this.$request.post("contracts/addMiner", {
                    contract: this.contract,
                    miner: this.miner.id,
                    status: this.status.value,
                    serial_number: this.serial_number
                }).then( rsp => {
                    this.$emit("onMinerAdd", {});
                    this.show = false;
                }).catch( err => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: `Ошибка добавления`,
                          text: err.info || err.message,
                          icon: 'AlertCircleIcon',
                          variant: 'danger',
                        }
                    });
                })
                
            },
            
            fetchMiners( query, loading ) {
                this.$request.get("miners/search", {
                    q: query
                }).then( rsp => {
                    this.miners = rsp.items;
                })
            }
        },
        components: {
            vSelect,
            ValidationProvider,
            ValidationObserver
        },
        watch: {

        },
        mounted() {

        }

    }

</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>