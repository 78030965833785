<template>
    <div>
        <b-card v-if="User.hasPermissions('miners', 'read')" no-body class='overflow-hidden'>
            
            <b-card-header>
                <b-card-title>
                    Майнеры
                </b-card-title>
                <b-button pill variant="primary" size="sm" @click="isMinerAddState = true">Добавить майнер</b-button>
            </b-card-header>
            
            <b-table-simple responsive >

                <b-thead class="bg-gray">
                    <b-tr>
                        <b-th class="text-nowrap">Модель</b-th>
                        <b-th v-if="isEdit" class="text-nowrap">Статус</b-th>
                        <b-th v-if="isEdit"></b-th>
                        <b-th v-if="!isEdit" class="text-nowrap">Хешрейт</b-th>
                        <b-th v-if="!isEdit" class="text-nowrap">Добыто</b-th>
                        <b-th class="text-nowrap">Серийный номер</b-th>
                        <b-th v-if="isEdit" class="text-nowrap">Погрешность хешрейта (%)</b-th>
                        <b-th v-if="isEdit" class="text-nowrap">Погрешность э.п. (%)</b-th>
                        <b-th></b-th>
                        <b-th></b-th>
                    </b-tr>
                </b-thead>

                <b-tbody>
                    <b-tr v-for="(item, index) in active" :key="index">
                        <b-td class="font-weight-bolder text-black text-ellipsis text-nowrap">
                            <b-link :to="linkGen({ item: item })">{{ item.machine.name }}</b-link>
                        </b-td>
                        <template v-if="item.is_edit">
                            <b-td colspan="2">
                                <b-form-select v-model="editable[item.id].status" size="sm" >
                                    <b-form-select-option :value="1">Доставка</b-form-select-option>
                                    <b-form-select-option :value="2">В работе</b-form-select-option>
                                    <b-form-select-option :value="3">Ремонт</b-form-select-option>
                                    <b-form-select-option :value="6">Приостановить</b-form-select-option>
                                </b-form-select>
                            </b-td>
                            <b-td>
                                <b-form-input placeholder="Серийный номер" size="sm" v-model="editable[item.id].serial_number"></b-form-input>
                            </b-td>
                            <b-td>
                                <b-form-input style="width: 70px;" placeholder="" size="sm" v-model="editable[item.id].hashrate_error"></b-form-input>
                            </b-td>
                            <b-td>
                                <b-form-input style="width: 70px;" placeholder="" size="sm" v-model="editable[item.id].energy_usage_error"></b-form-input>
                            </b-td>
                            <b-td style="width: 25px;">
                                <b-link v-if="User.hasPermissions('miners', 'edit')" @click="save(item)">
                                    <feather-icon icon="CheckCircleIcon" class="text-success" size="17" />
                                </b-link>
                            </b-td>
                            <b-td style="width: 25px;">
                                <b-link @click="setEditMode( item, false )">
                                    <feather-icon icon="XIcon" class="text-danger" size="17" />
                                </b-link>
                            </b-td>
                        </template>
                        <template v-else >
                            <b-td class="font-weight-normal text-black">{{ item.machine.hashrate }} <span class="text-muted">TH/s</span></b-td>
                            <b-td class="font-weight-normal text-black">{{ getValueByTicker(item.total_balance) }} <span class="text-muted">USD</span></b-td>
                            <b-td class="font-weight-normal text-black overflow-hidden text-ellipsis">
                                <b-badge v-if="item.status === 0" variant="danger" style="font-size: 8px;">
                                    Требуется оплата
                                </b-badge>
                                <b-badge v-else-if="item.status === 1" variant="primary" class='badge-start-view'>
                                    Старт {{ Utils.daysAgo( item.start_at ) }}
                                </b-badge>
                                <b-badge v-else-if="item.status === 3" variant="danger" style="font-size: 8px;">
                                    Ремонт
                                </b-badge>
                                <b-badge v-else-if="item.status === 6" style="font-size: 8px;">
                                    Приостановлен
                                </b-badge>
                                <span v-else-if="item.status === 2" class="text-ellipsis" style="font-size: 11px;">{{ item.serial_number }}</span>
                            </b-td>
                            <b-td style="width: 25px;">
                                <b-link v-if="User.hasPermissions('miners', 'edit')" @click="setEditMode( item, true )">
                                    <feather-icon icon="EditIcon" class="text-primary" size="17" />
                                </b-link>
                            </b-td>
                            <b-td style="width: 25px;">
                                <b-link v-if="User.hasPermissions('miners', 'delete')" @click="setDeleteState( item )">
                                    <feather-icon icon="TrashIcon" class="text-danger" size="17" />
                                </b-link>
                            </b-td>
                        </template>
                    </b-tr>
                    <template v-if="deleted.length > 0">
                        <b-tr class="bg-gray">
                            <b-td colspan="6" class="text-muted">
                                Архив
                            </b-td>
                        </b-tr>
                        <b-tr v-for="(item, index) in deleted" :key="index">
                            <b-td class="font-weight-bolder text-black text-ellipsis text-nowrap">
                                <b-link :to="linkGen({ item: item })">{{ item.machine.name }}</b-link>
                            </b-td>
                            <template v-if="item.is_edit">
                                <b-td colspan="2">
                                    <b-form-select v-model="editable[item.id].status" size="sm" >
                                        <b-form-select-option :value="1">Доставка</b-form-select-option>
                                        <b-form-select-option :value="2">В работе</b-form-select-option>
                                        <b-form-select-option :value="3">Ремонт</b-form-select-option>
                                        <b-form-select-option :value="6">Приостановить</b-form-select-option>
                                    </b-form-select>
                                </b-td>
                                <b-td>
                                    <b-form-input placeholder="Серийный номер" size="sm" v-model="editable[item.id].serial_number"></b-form-input>
                                </b-td>
                                <b-td style="width: 25px;">
                                    <b-link v-if="User.hasPermissions('miners', 'edit')" @click="save(item)">
                                        <feather-icon icon="CheckCircleIcon" class="text-success" size="17" />
                                    </b-link>
                                </b-td>
                                <b-td style="width: 25px;">
                                    <b-link @click="setEditMode( item, false )">
                                        <feather-icon icon="XIcon" class="text-danger" size="17" />
                                    </b-link>
                                </b-td>
                            </template>
                            <template v-else >
                                <b-td class="font-weight-normal text-black">{{ item.machine.hashrate }} <span class="text-muted">TH/s</span></b-td>
                                <b-td class="font-weight-normal text-black">{{ getValueByTicker(item.total_balance) }} <span class="text-muted">USD</span></b-td>
                                <b-td class="font-weight-normal text-black overflow-hidden text-ellipsis" colspan="3">
                                    
                                </b-td>
                                
                            </template>
                        </b-tr>
                    </template>
                </b-tbody>

             </b-table-simple>
            
        </b-card>
        
        <b-card v-else no-body bg-variant="transparent">
            <b-alert
                variant="danger"
                show
                class="m-0"
            >
                <div class="alert-heading">
                  Майнеры
                </div>
                <div class="alert-body">
                    У Вас нет прав доступа к данному модулю
                </div>
            </b-alert>
        </b-card>
        
        <miner-add-modal 
            ref="minerAddModal" 
            :contract="contract.id" 
            :show="isMinerAddState"
            @onMinerAdd="$emit('getContract')" 
            @closed="isMinerAddState = false"
        />
        
        <miner-delete-modal 
            :contract="contract.id" 
            :miner="miner"
            :show="isMinerDeleteState"
            @onMinerDelete="uploadContract" 
            @closed="isMinerDeleteState = false"
        />
        
    </div>
</template>

<script>
    
    import User from "@/modules/user/"
    import Utils from "@/modules/utils/"
    
    import MinerAddModal from "@/views/Administrator/Contracts/modals/MinerAddModal"
    import MinerDeleteModal from "@/views/Administrator/Contracts/modals/MinerDeleteModal"
    
    export default {

        data() {
            return {
                User,
                Utils,
                ticker: User.currency,
                isMinerAddState: false,
                isMinerDeleteState: false,
                miner: null,
                editable: {},
                isEdit: false,
            }
        },
        props: {
            contract: {
                type: Object
            }
        },
        methods: {

            uploadContract() {
                this.$emit('onMinerDelete')
                this.$emit('getContract')
            },
            
           setDeleteState( item ) {
               this.miner = item;
               this.isMinerDeleteState = true;
           }, 
            
           save( item ) {
               let params = this.editable[ item.id ];

               params['miner'] = item.id;
               this.$request.post('contracts/updateMiner', params ).then( rsp => {
                  item.status = params.status;
                  item.serial_number = params.serial_number;
                  item.energy_usage_error = params.energy_usage_error;
                  item.hashrate_error = params.hashrate_error;
                  this.setEditMode( item, false );
               });
           },
            
           setEditMode( item, val ) {
                          
               this.isEdit = val;

                if( val ) {
                    this.$set(this.editable, item.id, {
                        status: item.status,
                        serial_number: item.serial_number,
                        energy_usage_error: item.energy_usage_error,
                        hashrate_error: item.hashrate_error
                    });
                }
               
               this.$set( item, "is_edit", val ); 
               
           },
            
           getValueByTicker( value ) {
                
                if( this.ticker === "BTC" ) {
                    return value.toFixed(12);
                }
                
                return (value * this.$blockchain.tickers.values[User.currency].last).formatMoney(2, "", " ");
                
            }, 
            
           linkGen( args ) {
              return {
                  name: "admin-contracts-miner-view",
                  params: {
                      id: this.$route.params.id,
                      miner: args.item.guuid
                  }
              };
           },     
        },
        computed: {
            active() {
                return this.contract.miners.filter(item => item.status < 4 || item.status === 6 );
            },
            deleted() {
                return this.contract.miners.filter(item => item.status === 4 );
            }
        },
        components: {
            MinerAddModal,
            MinerDeleteModal
        },
        watch: {

        },
        mounted() {

        }

    }

</script>