<template>

    <b-overlay
      :show="is_loading"
      spinner-variant="primary"
      variant='transparent'
      style="min-height: 50vh;"
    >
          <b-row v-if='contract'>
              
            <b-col cols='12' class='mb-2'>
                <span style="font-size: 20px; font-weight: 600;">Контракт №{{ $route.params.id }}</span>
                <b-badge class="mt-auto mb-auto ml-1" v-if="contract.status === 0" variant="danger">Требуется оплата</b-badge>
                <b-badge class="mt-auto mb-auto ml-1" v-if="contract.status === 1" >Работает</b-badge>
                <b-badge class="mt-auto mb-auto ml-1" v-if="contract.status === 2" variant="primary">Оплата получена</b-badge>
                <b-badge class="mt-auto mb-auto ml-1 badge-start-view" v-if="contract.status === 3"  >Старт {{ Utils.daysAgo( contract.start_at ) }}</b-badge>
                <b-badge class="mt-auto mb-auto ml-1" v-if="contract.status === 4" variant="danger">Завершен</b-badge>
                <b-badge class="mt-auto mb-auto ml-1" v-if="contract.status === 5" variant="danger">Удален без оплаты</b-badge>
                <b-badge class="mt-auto mb-auto ml-1" v-if="contract.status === 6" variant="secondary">Архивный</b-badge>
                <b-badge class="mt-auto mb-auto ml-1" v-if="contract.status === 7" variant="secondary">Приостановлен</b-badge>
            </b-col>  
              
            <b-col md='3' lg='3' cols='12' class="mb-2">
                <card-view :contract='contract' />
            </b-col>
            <b-col md='9' lg='9' cols='12'>
                
                <b-row>
                    <b-col md="4" lg="4" cols="12">
                        <b-card no-body class="shadow">
                            <b-card-body style="padding: 16px;">
                                <div class="d-flex align-items-center">
                                    <div class="bg-light-success" style="width: 50px; height: 50px; line-height: 50px; text-align: center; border-radius: 12px;">
                                        <feather-icon icon="UserIcon" size="25" />
                                    </div>
                                    <div class="ml-1">
                                        <p class="m-0">
                                            <span class="font-weight-bolder">{{ toUSD(contract.current_balance).formatMoney(2," "," ") }} </span>
                                            <span class="text-muted">$</span>
                                        </p>
                                        <p class="m-0 text-muted font-small-3">Добыча пользователя</p>
                                    </div>
                                </div>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col md="4" lg="4" cols="12">
                        <b-card no-body class="shadow">
                            <b-card-body style="padding: 16px;">
                                <div class="d-flex align-items-center">
                                    <div class="bg-light-primary" style="width: 50px; height: 50px; line-height: 50px; text-align: center; border-radius: 12px;">
                                        <feather-icon icon="PackageIcon" size="25" />
                                    </div>
                                    <div class="ml-1">
                                        <p class="m-0">
                                            <span class="font-weight-bolder">{{ toUSD(contract.total_balance - contract.current_balance).formatMoney(2," "," ") }} </span>
                                            <span class="text-muted">$</span>
                                        </p>
                                        <p class="m-0 text-muted font-small-3">Добыча платформы</p>
                                    </div>
                                </div>
                            </b-card-body>    
                        </b-card>
                    </b-col>
                </b-row>
                
                <b-tabs pills >
                    <b-tab v-if="contract.type === 2" title="Майнеры">
                        <miners-view :contract="contract" @getContract="get" />
                    </b-tab>
                    <b-tab title="Статистика">
                        <statistic-view :contract="contract" />
                    </b-tab><!--
                    <b-tab title="График добычи">
                        <b-card no-body>
                            <graphic-view :contract="contract" />
                        </b-card>
                    </b-tab>-->
                </b-tabs>
            </b-col>
        </b-row>
        
      
        
    </b-overlay>
    
</template>

<script>

    import CardView from "./widgets/CardView"
    import MinersView from "./widgets/MinersView"
    import StatisticView from "./widgets/StatisticView"
    import Utils from "@/modules/utils/"
        
    export default {

        data() {
            return {
                Utils,
                is_loading: true,
                contract: null,
                isOnCreateContractState: false
            }
        },
        methods: {
            
            toUSD( value ) {
                return ( value * this.$blockchain.tickers.values['USD'].last );
            },
            
            get() {
                this.$request.get("contracts/get", {
                    id: this.$route.params.id
                }).then( result => {
                     this.contract = result;                 
                     this.is_loading = false;
                });
            }
        },
        components: {   
            CardView,
            MinersView,
            StatisticView
        },
        watch: {

        },
        mounted() {

            this.get();

        }

    }

</script>