<template>

    <div style="position: sticky; top: 16px;">
        <b-card class="m-0" no-body>
            <b-dropdown
                v-if="contract.status === 1 || contract.status === 7"
                size="sm"
                right
                no-caret   
                variant="transparent"
                style="position: absolute; right: 16px; top: 8px;"
                class="group-picker"
            >
                <template #button-content>
                     <feather-icon icon="MoreHorizontalIcon" class="text-muted" size="22" />
                </template>
                <b-dropdown-item v-if="contract.status === 1" @click="pause">
                    Приостановить работу контракта
                </b-dropdown-item>
                <b-dropdown-item v-if="contract.status === 7" @click="start">
                    Запустить контракт
                </b-dropdown-item>
            </b-dropdown>

            <b-card-body style="padding: 24px 24px 16px 24px">
                <p style="margin-bottom: 6px;"> Добыто чистыми <b-link @click="changeCurrency">в {{ ticker }}</b-link> </p>

                <div class="d-flex" style="padding-bottom: 6px;">
                    <p style="font-size: 28px; font-weight: 600;">
                        {{ (contract.current_balance * $blockchain.tickers.values[ticker].last).formatMoney(2, ' ', ' ') }} {{ User.getSymbol() }} 
                    </p>
                </div>
            
                <p style="margin-bottom: 6px; margin-top: 12px;" class="text-muted">Добыто всего </p>

                <div class="d-flex" style="padding-bottom: 6px;">
                    <p style="font-size: 28px; font-weight: 600;">
                        {{ (contract.total_balance * $blockchain.tickers.values[ticker].last).formatMoney(2, ' ', ' ') }} {{ User.getSymbol() }} 
                    </p>
                </div>

                <p class="text-muted" style="margin-bottom: 6px; margin-top: 12px;">Суммарная мощность</p>

                <div class="d-flex" style="padding-bottom: 6px;">

                    <div class="energy-icon mt-auto mb-auto" style="margin-right: 6px;"></div>

                    <p class="mb-0">
                        <span  style="font-size: 24px; font-weight: 500;">{{ contract.ths.formatMoney(2,' ', ' ') }}</span> 
                        <span style="font-size: 18px;"> TH/s</span>
                    </p>
                </div>
            </b-card-body>

            <hr class="m-0">

            <b-card-body style="padding: 16px 24px 24px 24px;">
                <div style="font-size: 14px; margin-bottom: 16px;">
                    
                    <div class="d-flex justify-content-between" style="margin: 8px 0;">
                        <span class="text-muted font-weight-light">
                            Клиент
                        </span>
                        <b-link :to="{ name: 'admin-users-view', params: { id: contract.creator.id }}">
                          {{ contract.creator.firstName }} {{ contract.creator.lastName }}
                        </b-link>
                    </div>
                    
                    <div class="d-flex justify-content-between" style="margin: 8px 0;">
                        <span class="text-muted font-weight-light">
                            Инвестиции
                        </span>
                        <span>
                           {{ convertUSDtoBTC(contract.investments).toFixed(8) }} BTC
                        </span>
                    </div>
                    
                    <div class="d-flex justify-content-between" style="margin: 8px 0;">
                        <span class="text-muted font-weight-light">
                            Тип контракта
                        </span>
                        <div class="d-flex align-items-center">
                            <template v-if="contract.type === 1">
                                <feather-icon icon="CloudIcon" size="13" class="text-primary" />
                                <span style="padding-left: 5px;">Облако</span>
                            </template>
                            <template v-if="contract.type === 2">
                                <feather-icon icon="CpuIcon" size="13" class="text-danger" />
                                <span  style="padding-left: 5px;">Майнер</span>
                            </template>
                        </div>
                    </div>

                    <div  class="d-flex justify-content-between" style="margin: 8px 0;">
                        <span class="text-muted font-weight-light">
                            Дата создания
                        </span>
                        <span >
                            {{ toTimeString(contract.created_at) }}
                        </span>
                    </div>

                    <div class="d-flex justify-content-between" style="margin: 8px 0;">
                        <span class="text-muted font-weight-light">
                            Дата начала
                        </span>
                       <span v-if="contract.status === 6">
                            -
                        </span>
                        <span v-else>
                            {{ toDateString(contract.start_at) }}
                        </span>
                    </div>


                    <div  class="d-flex justify-content-between" style="margin: 8px 0;">
                        <span class="text-muted font-weight-light">
                            Дата окончания
                        </span>
                        <span v-if="contract.status === 6">
                            -
                        </span>
                        <span v-else>
                            {{ toDateString(contract.end_at) }}
                        </span>
                    </div>

                    <div v-if="contract.status === 1" class="d-flex justify-content-between" style="margin: 8px 0;">
                        <span class="text-muted font-weight-light">
                            Прогресс
                        </span>
                       <b-progress :max="100" height="12px" style="width: 70px; margin-left: auto; margin-right: 4px; margin-top: auto; margin-bottom: auto;">
                            <b-progress-bar
                              variant="primary"
                              :value="getCurrentPercent()"
                              v-b-tooltip.hover :title="`Общий доход ${(contract.total_balance * $blockchain.tickers.values['USD'].last).formatMoney(2, '', ' ')} USD `"
                              label=" "
                            />
                        </b-progress>
                        <span class="text-muted mt-auto mb-auto" style="font-size: 12px;">
                            {{ getCurrentPercent().toFixed(1) }}%
                        </span>
                    </div>
                </div>
                <template>
                    <hr>
                    <b-button v-if="!contract.is_anonymous" :href="doc_pdf" target="_blank" variant="" block >Скачать договор</b-button>
                </template>
                <template v-if="contract.status === 5">
                    <hr>
                    <b-button @click='reactivate' variant='primary' block>
                        Восстановить
                    </b-button>
                    <b-button @click='forceDelete' variant='danger' block>
                        Удалить контракт
                    </b-button>
                </template>
                
            </b-card-body>
        </b-card>
        
    </div>
    
</template>

<script>

    import User from "@/modules/user/"
    import Blockchain from "@/modules/blockchain"

    export default {

        data() {
            return {
                User,
                Blockchain
            }
        },
        props: {
            contract: {
                type: Object
            }
        },
        methods: {
            
            
            forceDelete( args ) {
                this.$request.post("contracts/delete", {
                    id: this.contract.id
                }).then( r => {
                    this.$router.go(-1);
                }) ;
            },
            
            
            reactivate( args ) {
                this.$request.post("contracts/reactivate", {
                    id: this.contract.id
                }).then( r => {
                    this.contract.status = 0;
                }) ;
            },
            
            convertUSDtoBTC( value ) {
                return ( value / Blockchain.tickers.values['USD'].last );
            },
            
            getCurrentPercent() {
                if( this.contract.type === 2 ) {
                    // let percent = ( (Math.floor(Date.now() / 1000)) / this.contract.end_at );
                    // return  100 - (percent * 100) ;

                    return 100 * ((Math.floor(Date.now() / 1000)) - this.contract.start_at) / (this.contract.end_at - this.contract.start_at)
                }
                return ((this.contract.total_balance * this.$blockchain.tickers.values['USD'].last ) / this.getPeriodProfitUSD()) * 100;     
            },
            changeCurrency( event ) {
                event.stopPropagation();
                this.$router.push(`${this.$route.path}?act=currency`);
            },
            getPeriodProfitUSD() {
                return (((84600 * 30 * this.contract.period * User.contracts.values.R * ( User.contracts.values.H * this.contract.ths )) / ( User.contracts.values.D * Math.pow(2, 32) )) * this.$blockchain.tickers.values["USD"].last) ;                
            },

            getPeriodProfit() {
                return (((84600 * 30 * this.contract.period * User.contracts.values.R * ( User.contracts.values.H * this.contract.ths )) / ( User.contracts.values.D * Math.pow(2, 32) )) * this.$blockchain.tickers.values[this.ticker].last) ;                
            },
            
            toDateString( timestamp ) {
                
                let date = new Date(timestamp * 1000);
                return new Intl.DateTimeFormat('ru-RU', {
                    day: "2-digit",
                    month: '2-digit',
                    year: "numeric",
                }).format(date);
                
            },

            toTimeString( timestamp ) {

                let date = new Date(timestamp * 1000);
                return new Intl.DateTimeFormat('ru-RU', {
                    day: "2-digit",
                    month: '2-digit',
                    year: "numeric",
                    hour: 'numeric', minute: 'numeric',
                    hour12: false,
                }).format(date);

            },

            pause() {
                this.$request.post('contracts/pauseContract', {contract: this.contract.id} ).then( rsp => {
                   this.contract.status = 7;
                });
            },

            start() {
                this.$request.post('contracts/startContract', {contract: this.contract.id} ).then( rsp => {
                   this.contract.status = 1;
                });
            }
            
        },
        computed: {
            ticker() {
                return User.currency;
            },
            
            user() {
                return User;
            },
              
            doc_pdf() {
                return `${process.env.VUE_APP_BASE_URL}admin/doc/${this.contract.doc}`
            },          
        },
        components: {

        },
        watch: {

        },
        mounted() {
        }

    }

</script>